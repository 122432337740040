/* CalendarComponent.css */
.calendar-container {
  height: 100%;
  background-color: white;
  border-radius: 8px;
  font-family:
    "Albert Sans",
    system-ui,
    -apple-system,
    sans-serif;
}

.rbc-button-link {
  font-weight: 600;
  font-family:
    "Albert Sans",
    system-ui,
    -apple-system,
    sans-serif;
}

.rbc-event-content {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  /* Truncate to one line */
  white-space: nowrap;
  overflow: hidden;
}

.rbc-day-slot .rbc-event-content {
  line-height: 12px;
  flex-grow: 0;
}

.rbc-event-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.hide-event-label .rbc-event-label {
  display: none;
}

.custom-calendar .rbc-event {
  display: flex;
  /* Clever trick - Reverse puts the name above the time. */
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.date-range {
  font-size: 1.125rem;
  font-weight: 500;
}

.header-buttons {
  display: flex;
  gap: 16px;
}

.header-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.today-button {
  background-color: #f3f4f6;
}

.calendars-button {
  background-color: white;
  border: 1px solid #e5e7eb !important;
}

.view-button {
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  gap: 8px;
}

.list-text {
  color: #9ca3af;
}

/* Custom Calendar Styles */
.custom-calendar {
  background: white;
  border: none;
  height: 100%;
}

.custom-calendar .rbc-header {
  padding: 8px;
  font-weight: normal;
  border-bottom: none;
  border-radius: 12px 12px 0 0;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: none;
}

.custom-calendar .rbc-time-slot {
  border-top: none;
}

.custom-calendar .rbc-event {
  border: none;
  border-radius: 4px;
  padding: 4px 5px 2px 5px !important;
}

.custom-calendar .rbc-time-header-content {
  border-left: none;
}

.rbc-time-header.rbc-overflowing {
  border-right: none;
}

.rbc-row.rbc-time-header-cell {
  min-height: unset;
}

.custom-calendar .rbc-today {
  background-color: #f7fafc;
}

.rbc-off-range-bg {
  background: #faf9fa;
}

.rbc-month-view {
  border-radius: 12px;
  border-color: #eaebec;
}

.rbc-month-header {
  border-bottom: 1px solid #eaebec;
}

.rbc-month-row + .rbc-month-row {
  border-color: #eaebec;
}

.custom-calendar .rbc-time-content {
  border-top: 1px solid #d4d4d4;
}

.custom-calendar .rbc-time-gutter {
  font-size: 0.875rem;
  color: #4a5568;
}

.custom-calendar .rbc-time-view {
  border: none;
}

/* Additional styles for event colors */
.custom-calendar .rbc-event.social-meeting {
  background-color: #c6f6d5;
  color: #22543d;
}

.custom-calendar .rbc-event.cruise-package {
  background-color: #e9d8fd;
  color: #44337a;
}

.custom-calendar .rbc-event.partner-discussion {
  background-color: #feebc8;
  color: #7b341e;
}

.custom-calendar .rbc-event.booking-review {
  background-color: #bee3f8;
  color: #2c5282;
}

/* Responsive styles */
@media (max-width: 768px) {
  .calendar-header {
    flex-direction: column;
    gap: 16px;
  }

  .header-buttons {
    width: 100%;
    justify-content: space-between;
  }
}

/* Time column formatting */
.custom-calendar .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: none;
  min-height: 48px;
}
.rbc-timeslot-group {
  min-height: 48px;
}

.custom-calendar .rbc-time-gutter {
  margin-top: -12px;
  padding-right: 4px;
}

.rbc-label {
  font-weight: 600;
  color: #8e9598;
}

.rbc-timeslot-group:first-child .rbc-time-slot {
  display: none;
}

/* Current time indicator */
.custom-calendar .rbc-current-time-indicator {
  background-color: #ef7b77;
  height: 2px;
}

@keyframes growWidth {
  from {
    width: 0;
    min-width: 0;
  }
  to {
    width: 55.7578px;
    min-width: 55.7578px;
  }
}
.rbc-time-header-gutter {
  width: 0px;
  /* Not a great solution, but this gutter is added post-render by RBC and animating it makes it less jerky */
  animation: growWidth 0.3s ease-in forwards;
}

.faye-internal-event .rbc-event-content {
  overflow: visible;
}

.faye-internal-event .rbc-event-content::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("/public/assets/images/faye-mini-icon.png"); /* Replace with your icon URL */
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 3px; /* Creates the gap */
  margin-bottom: -3px; /* Aligns the icon with the text */
}

.rbc-month-event {
  font-size: 12px;
}
.rbc-month-event-time {
  font-weight: 400;
  padding-right: 4px;
}
.rbc-month-event-label {
  font-weight: 500;
  text-overflow: clip !important;
}
.custom-calendar .rbc-month-view .rbc-event:not(.rbc-all-day-event-cell) {
  background-color: #fff !important;
  border-width: 2px;
  border-style: solid;
  padding: 2px 3px 0px 3px !important;
}
.custom-calendar .rbc-month-view .rbc-event-content {
  text-overflow: clip;
}
