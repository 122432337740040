.pac-container {
  border-radius: 8px;
  margin-left: 6px;
  margin-top: 14px;
  z-index: 10000;
  box-shadow: none;
  border: 1px solid #d9d9d9;
}

.pac-container .pac-item {
  padding: 10px 20px;
  border-top: none;
}

.pac-logo:after {
  display: none;
}
