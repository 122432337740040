/* Container styles */
.dialpad-container {
  max-width: 20rem; /* max-w-xs */
  margin-left: auto;
  margin-right: auto;
  padding: 1rem; /* p-4 */
  background-color: #f3f4f6; /* bg-gray-100 */
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); /* shadow-md */
}

/* Grid layout for buttons */
.button-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* grid-cols-3 */
  gap: 1rem; /* gap-4 */
}

/* Button base styles */
.dialpad-button {
  aspect-ratio: 1 / 1;
  border-radius: 9999px; /* rounded-full */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.875rem; /* text-3xl */
  font-weight: 300; /* font-light */
  background-color: white;
  border: 2px solid #e5e7eb; /* border-2 border-gray-200 */
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Button hover state */
.dialpad-button:hover {
  background-color: #f3f4f6; /* hover:bg-gray-100 */
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); /* hover:shadow-lg */
}

/* Button pressed state */
.dialpad-button:active,
.dialpad-button.pressed {
  background-color: #d1d5db; /* bg-gray-300 */
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); /* shadow-inner */
  transform: scale(0.95); /* scale-95 */
}

/* Button subtext */
.button-subtext {
  font-size: 0.75rem; /* text-xs */
  margin-top: 0.25rem; /* mt-1 */
  font-weight: 400; /* font-normal */
}
